import { graphql } from 'gatsby';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Grid from '../components/grid';
import Box from '../components/box';
import Video from '../components/video';
import FilterBar from '../components/filter-bar';
import MediaCard from '../components/media-card';
import MediaForm from '../components/media-form';
import Subscribe from '../components/subscribe';

export default function Media({ data }) {
  const [kits, setKits] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [activeDisciplines, setActiveDisciplines] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/');
  }, []);

  useLayoutEffect(() => {
    setKits(data.media.kits);

    let newDisciplines = data.media.kits
      .map((kit) => kit.discipline)
      .flat()
      .filter(Boolean)
      .sort((a, b) => a.title.localeCompare(b.title));
    newDisciplines = [...new Map(newDisciplines.map((item) => [item['title'], item])).values()];
    setDisciplines(newDisciplines);
  }, [data.media.kits]);

  useLayoutEffect(() => {
    setProfiles(data.media.profiles);
  }, [data.media.profiles]);

  useLayoutEffect(() => {
    let tempKits = data.media.kits;

    if (activeDisciplines.length) {
      tempKits = tempKits.filter((project) => {
        return project.discipline
          .map((discipline) => discipline.title)
          .some((discipline) => activeDisciplines.includes(discipline));
      });
    }

    setKits(tempKits);
  }, [activeDisciplines]);

  function actionDisciplines(filter) {
    if (!filter) {
      setActiveDisciplines([]);
    } else {
      if (activeDisciplines.includes(filter)) {
        setActiveDisciplines([...activeDisciplines.filter((el) => el !== filter)]);
      } else {
        setActiveDisciplines([...activeDisciplines, filter]);
      }
    }
  }

  return (
    <div
      css={css`
        padding-top: var(--marginPageTop);
      `}
    >
      <SEO
        title="Media"
        description={
          data.media._rawIntroText
            ? data.media._rawIntroText
                .map((el) => el.children)
                .flat()
                .map((el) => el?.text)
                .join(' ')
            : null
        }
        imageID={data.media.introMedia.type === 0 ? data.media.introMedia.image.asset._id : null}
      />
      <Box x={10} y={6}>
        <BlockContent className="type--heading-four" content={data.media._rawIntroText} />
      </Box>
      <Box y={10}>
        {data.media.introMedia.type === 0 && (
          <div
            css={css`
              img {
                display: block;
                width: 100%;
                height: auto;
              }
            `}
          >
            <SanityImage width={1980} {...data.media.introMedia.image} />
          </div>
        )}
        {data.media.introMedia.type === 1 && (
          <Video
            src={data.media.introMedia.video.asset.url}
            autoPlay={false}
            muted={false}
            loop={false}
            css={css`
              video {
                width: 100%;
                height: auto;
              }
            `}
          />
        )}
      </Box>
      <Box x={10} y={9}>
        <h2
          className="type--heading-three"
          css={css`
            margin-bottom: var(--margin8);
          `}
        >
          Media Kits
        </h2>
      </Box>
      <Box x={10} y={9}>
        <FilterBar
          activeFilters={activeDisciplines}
          actionFilter={actionDisciplines}
          filters={[...disciplines.map((discipline) => discipline.title)]}
          feedTitle="Media"
        />
      </Box>
      <Box x={10} y={9}>
        <Grid
          css={css`
            row-gap: var(--margin8);
          `}
        >
          {kits.length > 0 &&
            kits.map((kit, i) => (
              <MediaCard
                key={i}
                image={kit.image}
                title={kit.title}
                fileExtension={kit.file?.asset.extension}
                fileSize={`${Math.round(kit.file?.asset.size / 1000000)}MB`}
                fileURL={kit.file?.asset.url}
                css={css`
                  grid-column-start: span 4;

                  @media (max-width: 800px) {
                    grid-column-start: span 6;
                  }

                  @media (max-width: 550px) {
                    grid-column-start: span 8;
                  }
                `}
              />
            ))}
        </Grid>
      </Box>
      {profiles.length > 0 && <div
        css={css`
          background-color: var(--gray-light);
          padding: 1px 0 var(--margin);
        `}
      >
        <Box x={10} y={9}>
          <h2
            className="type--heading-three"
            css={css`
              margin-bottom: var(--margin8);
            `}
          >
            Profiles
          </h2>
        </Box>
        <Box x={10} y={9}>
          <Grid
            css={css`
              row-gap: var(--margin8);
            `}
          >
            {profiles.map((profile, i) => (
                <MediaCard
                  key={i}
                  image={profile.image}
                  title={
                    <div>
                      {profile.name}
                      <br />
                      {profile.position}
                    </div>
                  }
                  fileExtension={profile.file.asset?.extension}
                  fileSize={`${Math.round(profile.file.asset?.size / 1000000)}MB`}
                  fileURL={profile.file.asset?.url}
                  css={css`
                    grid-column-start: span 4;

                    @media (max-width: 800px) {
                      grid-column-start: span 6;
                    }

                    @media (max-width: 550px) {
                      grid-column-start: span 8;
                    }
                  `}
                />
              ))}
          </Grid>
        </Box>
      </div>}
      <Box
        y={10}
      >
        <Subscribe />
      </Box>
      {false && <Box>
        <MediaForm />
      </Box>}
    </div>
  );
}

export const query = graphql`
  query {
    media: sanityMedia(_id: { regex: "/(drafts\\\\.media)?/" }) {
      _rawIntroText(resolveReferences: { maxDepth: 10 })
      introMedia {
        type
        image {
          ...Image
        }
        video {
          asset {
            url
          }
        }
      }
      kits {
        title
        discipline {
          title
          slug {
            current
          }
        }
        image {
          ...Image
        }
        file {
          asset {
            url
            size
            extension
          }
        }
      }
      profiles {
        name
        position
        image {
          ...Image
        }
        file {
          asset {
            url
            size
            extension
          }
        }
      }
    }
  }
`;
