import React, { useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { Download } from './icons';

export default function MediaCard({ className, image, title, fileExtension, fileSize, fileURL }) {
  return (
    <div
      className={className}
      css={css`
        background-color: var(--white);
        filter: var(--dropShadow);
      `}
    >
      {image && (
        <SanityImage
          {...image}
          width={660}
          style={{
            display: 'block',
            width: '100%',
            aspectRatio: '9/6',
            objectFit: 'cover',
          }}
        />
      )}
      <div
        css={css`
          display: flex;
          min-height: 14rem;
          padding: 2.1rem 2.4rem 2.1rem 2.6rem;

          @media (max-width: 800px) {
            min-height: 12rem;
            padding: 1.8rem var(--gutterHalf) 1.2rem;
          }

          @media (max-width: 550px) {
            min-height: 10rem;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
          `}
        >
          <div
            className="type--paragraph"
            css={css`
              flex-basis: 100%;
              margin-bottom: 1rem;
            `}
          >
            {title}
          </div>
          <span
            className="type--body-small"
            css={css`
              flex-basis: 100%;
              margin-top: auto;
              padding-right: var(--gutterHalf);
            `}
          >
            ({fileExtension} {fileSize})
          </span>
        </div>
        <div
          css={css`
            margin-top: auto;
          `}
        >
          <Download url={fileURL} download={true} />
        </div>
      </div>
    </div>
  );
}
